import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'underscore';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  DocumentFilter,
  ClassValue,
  DocType,
  Class,
  AuthService,
  CompanyConfigurationService,
  FeedbackService,
  DocumentService,
  CompanyRole,
  ClassSelection,
  UserAction,
  PermissionsService,
  NotificationHubService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { AddClassValueDialogComponent } from './add-class-value-dialog/add-class-value-dialog.component';
import { AskDialogComponent } from '../_shared/ask-dialog/ask-dialog.component';
import { Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'class-selector',
  templateUrl: './class-selector.component.html',
  styleUrls: ['./class-selector.component.scss']
})
export class ClassSelectorComponent implements OnDestroy
{
  _disabled: boolean;
  private noEventOptions = { emitEvent: false};

  @Input() showAllText: boolean = false;

  @Input() set disabled(d: boolean)
  {
    if (d === this._disabled || !this.filters)
    {
      return;
    }
    // #BUG 10270 EXT-IMPORT (Filters disabled , problem with fromGroups)
    // Handle disabled mat-select with [disabled] option in HTML
    // if (d)
    // {
    //   this.filters.disable(this.noEventOptions);
    // }
    // else
    // {
    //   this.filters.enable(this.noEventOptions);
    // }
    this._disabled = d;
  }

  @Input() multiClassSelection: boolean;
  @Input() multiDocTypeSelection: boolean;
  @Input() ignoreSingleValueConfig: boolean;
  @Input() controlRequiredValues: boolean;
  /**
   * When a document type is selected, classValues are
   * automatically selected IF they are unique for a class
   */
  @Input() autoSelect: boolean;
  @Input() disableDocumentType: boolean;
  @Input() hideSearch: boolean;
  @Input() showCRUD: boolean;

  @Input() showStatusFilter: boolean;

  @Input() set preselection (filters: DocumentFilter)
  {
    this.preselect(filters);
  }

  private async preselect(filters: DocumentFilter)
  {
    console.log('ClassSelectorComponent - preselection start');
    if (this.multiDocTypeSelection === true)
    {
      /* This should happen only on search component */
      console.log('No preselection for multiSelection , reseting filters');
      this.resetFilter();
      return;
    }
    if (!filters || !filters.docTypeIds || filters.docTypeIds.length === 0)
    {
      this.docTypes.setValue(undefined);
      return;
    }
    if (filters.docTypeIds.length !== 1)
    {
      throw Error('Preselection needs exactly one document type');
    }
    if (filters)
    {
      console.log('Running preselection...');
      this.companyId = filters.companyId;
      await this.refreshCompany();
      const docType = _.find(this.documentTypes, d => d.id === filters.docTypeIds[0]);
      this.docTypes.setValue(docType, this.noEventOptions);
      this.documentTypesChanged(docType, false).then(() =>
      {
        for (const cls of this.selectedClasses)
        {
          const classSelection = _.find(filters.classSelections, cs => cs.classId === cls.id);
          const value: ClassValue[] = [];
          if (classSelection)
          {
            for (const classValueId of classSelection.classValueIds)
            {
              value.push(_.find(cls.classValues, classValue => classValue.id === classValueId));
            }
          }
          if (cls.singleValue)
          {
            this.filters.controls[cls.id].setValue(value[0], this.noEventOptions);
          }
          else
          {
            this.filters.controls[cls.id].setValue(value, this.noEventOptions);
          }
          this.reduceChildValues(value);
        }
      });
    }
  }

  /**
   * A button next to the doctype that emits the
   * autoAssignClicked event. The parent component
   * is responsible about what to do with it. It
   * is used in Extension/Import so the user decides
   * if to use the classification of existing documents.
   */
  @Input() showAutoAssignButton: boolean;
  @Output() autoAssignClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input() showResetButton: boolean;
  @Input() hideHardResetButton: boolean;

  @Output() filterChanged: EventEmitter<DocumentFilter> = new EventEmitter<DocumentFilter>();

  @Input() showAutoClassification: boolean;
  @Output() doContentClassification: EventEmitter<Class[]> = new EventEmitter<Class []>();

  search: UntypedFormControl = new UntypedFormControl();
  classSearchCtrl: UntypedFormControl = new UntypedFormControl();

  isSuperUser: boolean;
  isPreapproveEnabled: boolean;

  documentTypes: DocType[];
  docTypes = new UntypedFormControl();
  classes: Class[];
  selectedClasses: Class[] = [];
  filters: UntypedFormGroup;

  selectedDocTypes: DocType[] = [];

  filterValue: string = '';

  // docVersion states
  draft: boolean;
  preapproved: boolean;
  approved: boolean;

  subscriptions: Subscription[] = [];
  companyId: string;
  refreshingCompany: boolean;

  @Output() classValueUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor(private authService: AuthService, private configService: CompanyConfigurationService,
    private fb: UntypedFormBuilder, public dialog: MatDialog,
    private feedbackService: FeedbackService, private docService: DocumentService,
    private permissionsService: PermissionsService, private notificationHubService: NotificationHubService)
  {
    this.filters = this.fb.group({});
    this.docTypes.valueChanges.subscribe(this.documentTypesChanged);
    this.resetFilter();
    this.subscriptions.push(this.authService.identityChanged.subscribe((id) =>
    {
      if (this.companyId !== id.company.id)
      {
        this.resetFilter();
        this.checkIfPreapproveIsEnabled();
      }
      this.companyId = id.company.id;
    }));
    this.subscriptions.push(this.notificationHubService.companyUpdateChannelPushed.pipe(debounceTime(1000)).subscribe(() =>
    {
      this.updateClassValuesFilters();
    }));
    this.checkIfPreapproveIsEnabled();
  }

  ngOnDestroy()
  {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private checkIfPreapproveIsEnabled()
  {
    const identity = this.authService.getCurrentIdentity();
    this.isPreapproveEnabled = identity?.company?.configuration?.allowDocumentsPreapproval;
  }

  isMultipleSelectionForClass(cls: Class): boolean
  {
    return this.multiClassSelection && (!cls.singleValue || this.ignoreSingleValueConfig);
  }

  showAddClassValueButton(): boolean
  {
    return this.permissionsService.currentUserHasPermissionTo(UserAction.adminClassValues) && this.showCRUD;
  }

  isUser(): boolean
  {
    const id = this.authService.getCurrentIdentity();
    return id && id.role >= CompanyRole.user;
  }

  isOneParentSelected(cls: Class): boolean
  {
    if (cls.parent)
    {
      // parentValue can be an array or a classValue depending on class.singleValue = true/false
      const parentValue: ClassValue | ClassValue[] = this.filters.get(cls.parent.id)?.value;
      if ((<ClassValue>parentValue)?.id)
      {
        // parentValue is a classValue
        return true;
      }
      else if ((<ClassValue[]>parentValue)?.length === 1)
      {
        // parentValue is an array of classValue and has only one selected value
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return true;
    }
  }

  isOneValueSelected(cls: Class): boolean
  {
    const value = this.filters.get(cls.id).value;
    if ((cls.singleValue && value) || (value && value.length === 1))
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  applyFilter(filterValue: string, cls: Class)
  {
    const classValues: ClassValue[] = cls.reducedClassValues; // todo remove this.classValueOptions(cls);
    let result: ClassValue[];
    if (filterValue)
    {
      this.filterValue = filterValue;
      console.log(`filtering by ${filterValue}`);
      result = this.filters.controls[cls.id].value;
      const filteredValues = _.filter(classValues, clsValue =>
        clsValue.name.toLowerCase().includes(filterValue.toLowerCase()) ||
        (clsValue.code && clsValue.code.toLowerCase().includes(filterValue.toLowerCase()))
      );
      result = _.union(result, filteredValues);
    }
    else
    {
      result = classValues;
      this.filterValue = '';
    }
    cls.filteredValues = result;
  }

  resetClassFilteredValues(cls: Class)
  {
    cls.filteredValues = cls.reducedClassValues;
    this.filterValue = '';
    this.classSearchCtrl.setValue('');
  }

  checkFilter(cls: Class)
  {
    if (cls.filteredValues)
    {
      return cls.filteredValues;
    }
    else
    {
      return cls.reducedClassValues;
    }
  }

  resetFilter(emit: boolean = false, resetDocType: boolean = false)
  {
    this.search.setValue('');
    this.draft = false;
    this.preapproved = false;
    this.approved = false;

    const id = this.authService.getCurrentIdentity();
    this.isSuperUser = id.user && id.user.isSuperUser;
    this.selectedClasses = [];
    this.filters = this.fb.group({});

    // A deep copy of the classes is needed, if not all the classSelector instances
    // will share the class.filteredValues / reducedValues
    this.classes = JSON.parse(JSON.stringify(id.company.classes));

    if (id.company && id.company.classes && !resetDocType)
    {
      const noneLibraryTypes = _.filter(id.company.documentTypes, dt => !dt.isLibraryType);
      this.documentTypes = _.sortBy(noneLibraryTypes, dt => dt.name.toLowerCase());

      if (this.documentTypes.length === 1)
      {
        if (!this.multiDocTypeSelection)
        {
          this.docTypes.setValue(this.documentTypes[0]);
        }
        else
        {
          this.docTypes.setValue(this.documentTypes);
        }
      }
      else if (this.showAllText)
      {
        this.docTypes.setValue([]);
      }
    }
    else
    {
      this.docTypes.setValue([]);
    }
  }

  private updateClassValuesFilters()
  {
    const identity = this.authService.getCurrentIdentity();
    const classes = identity.company?.classes;
    if (!classes || classes.length === 0)
    {
      return;
    }

    const updatedClasses = this.docService.getPlainClassList(classes);

    for (const currentCls of this.selectedClasses)
    {
      const updatedClass = updatedClasses.find(cls => cls.id === currentCls.id);

      if (updatedClass)
      {
        // Compare updated class values and old/current class values
        const missingValues = updatedClass.classValues.filter(updatedValue =>
          !currentCls.classValues.some(currentValue => currentValue.id === updatedValue.id)
        );

        const extraValues = currentCls.classValues.filter(currentValue =>
          !updatedClass.classValues.some(updatedValue => updatedValue.id === currentValue.id)
        );

        if (missingValues.length > 0 || extraValues.length > 0)
        {
          // Add new values
          currentCls.classValues.push(...missingValues);

          // Remove deleted values
          currentCls.classValues = currentCls.classValues.filter(currentValue =>
            !extraValues.some(extraValue => extraValue.id === currentValue.id)
          );

          currentCls.reducedClassValues = currentCls.classValues;
        }
      }
    }
  }

  async hardFilterReset()
  {
    await this.refreshCompany();
    this.resetFilter();
  }

  async refreshCompany()
  {
    if (this.refreshingCompany)
    {
      return;
    }
    try
    {
      this.refreshingCompany = true;
      await this.authService.updateCompany();
      this.updateClassValuesFilters();
      this.resetFilter();
    }
    catch (err)
    {
      console.log('Error refreshing company', err);
    }
    finally
    {
      this.refreshingCompany = false;
    }
  }

  docTypeCheckAllChanged(checked: boolean)
  {
    let newValues: any = [];
    if (checked)
    {
      this.selectedDocTypes = this.documentTypes;
      newValues = _.clone(this.selectedDocTypes);
    }
    else
    {
      this.selectedDocTypes = [];
    }
    this.docTypes.setValue(newValues);
  }

  docTypeShowAllCheckbox(): number
  {
    if (this.documentTypes && this.documentTypes.length > 0)
    {
      if (this.selectedDocTypes.length === this.documentTypes.length)
      {
        return 1; // to show checked
      }
      if (this.selectedDocTypes.length > 0)
      {
        return 2; // to show indeterminate
      }
      return 0; // not checked
    }
    return -1; // to hide
  }

  docTypeSelectMultiple(checked: boolean, docType: DocType)
  {
    let newValue: any = [];
    newValue = _.clone(this.selectedDocTypes);
    if (checked)
    {
      newValue.push(docType);
    }
    else
    {
      newValue = _.without(newValue, docType);
    }
    this.docTypes.setValue(newValue);
  }

  isChecked(docType: DocType): boolean
  {
    const selectedDocTypes: DocType[] = this.docTypes.value;
    if (_.find(selectedDocTypes, dt => dt.id === docType.id))
    {
      return true;
    }
    return false;
  }

  clsCheckAllChanged(checked: boolean, cls: Class)
  {
    const clsCtrl = this.filters.get(cls.id);
    let newValues: any[] = [];
    if (checked)
    {
      newValues = _.clone(cls.reducedClassValues);
    }
    else
    {
      newValues = [];
    }
    if (cls.blankCheck)
    {
      newValues.unshift('blank');
    }
    clsCtrl.setValue(newValues);
  }

  clsShowAllCheckbox(cls: Class): number
  {
    const clsCtrlValue = this.filters.get(cls.id).value;
    if (cls && cls.classValues && cls.classValues.length > 0)
    {
      if (clsCtrlValue && clsCtrlValue.length === cls.classValues.length)
      {
        return 1; // to show checked
      }
      if (clsCtrlValue && clsCtrlValue.length > 0)
      {
        return 2; // to show indeterminate
      }
      return 0; // not checked
    }
    return -1; // to hide
  }

  clsBlankChanged(cls: Class)
  {
    cls.blankCheck = !cls.blankCheck;
    this.emit();
  }

  async addValue(cls: Class, cvName?: string)
  {
    const id = this.authService.getCurrentIdentity();
    if ((!this.isOneParentSelected(cls) || !id.company))
    {
      const msg = `At least one value (and only one) must be selected in the class "${cls.parent.name}"`;
      this.feedbackService.notifyMessage(msg);
      return;
    }

    cls = this.selectedClasses.find(c => c.id === cls.id);
    let clsParentValue: ClassValue;
    if (cls.parentId)
    {
      const parentValue: ClassValue | ClassValue[] = this.filters.get(cls.parentId).value;
      if ((<ClassValue>parentValue).id)
      {
        clsParentValue = <ClassValue>parentValue;
      }
      else if ((<ClassValue[]>parentValue).length === 1)
      {
        clsParentValue = (<ClassValue[]>parentValue)[0];
      }
    }

    let classValue: ClassValue;
    try
    {
      if (clsParentValue)
      {
        const newNameAndCode = await this.valueDialog(
          `New '${cls.name}' for '${clsParentValue.name}'`,
          cls.classValues,
          clsParentValue,
          undefined,
          cvName);
        if (!newNameAndCode || !newNameAndCode[0])
        {
          return;
        }
        classValue = {name: newNameAndCode[0], code: newNameAndCode[1], class: cls, parent: clsParentValue};
      }
      else
      {
        const newNameAndCode = await this.valueDialog(
          `New '${cls.name}' for company '${id.company.name}'`,
          cls.classValues,
          undefined,
          undefined,
          cvName);
        if (!newNameAndCode || !newNameAndCode[0])
        {
          return;
        }
        classValue = {name: newNameAndCode[0], code: newNameAndCode[1], class: cls};
      }

      classValue = await this.configService.postClassValue(classValue);

      cls.classValues.push(classValue);
      if (clsParentValue)
      {
        if (!clsParentValue.children)
        {
          clsParentValue.children = [];
        }
        clsParentValue.children.push(classValue);
        cls.reducedClassValues = cls.classValues.filter(c => c.parentId === clsParentValue.id);
      }
      else
      {
        cls.reducedClassValues = cls.classValues.slice(0);
      }
      cls.filteredValues = cls.reducedClassValues.slice(0);

      if (cls.singleValue)
      {
        this.filters.controls[cls.id].setValue(classValue);
      }
      else
      {
        this.filters.controls[cls.id].setValue([classValue]);
      }
    }
    catch (err)
    {
      let msg = `Error adding '${cls.name}'`;
      if (err && err.error)
      {
        msg = err.error;
      }
      this.feedbackService.notifyError(msg, err);
    }
  }

  async editValue(cls: Class, classValue: ClassValue)
  {
    try
    {
      if (!cls)
      {
        return;
      }
      if (!classValue)
      {
        return;
      }
      const newNameAndCode = await this.valueDialog(`Edit '${cls.name}'`, cls.classValues, null, classValue);
      if (!newNameAndCode || !newNameAndCode[0])
      {
        return;
      }
      classValue.name = newNameAndCode[0];
      classValue.code = newNameAndCode[1];
      await this.configService.postClassValue(classValue);
      this.classValueUpdated.emit();
    }
    catch (err)
    {
      this.feedbackService.notifyError(`Error editing '${cls.name}'`, err);
    }
  }

  async deleteValue(cls: Class, classValue: ClassValue)
  {
    const answer = await this.askDialog(`Do you really want to delete the '${cls.name}': ${classValue.name}?`);
    if (answer === 'yes')
    {
      try
      {

        if (classValue.children && classValue.children.length > 0)
        {
          await this.feedbackService.notifyMessage(`'${cls.name}' could not be deleted, please delete all dependencies first!`);
        }
        else
        {
          classValue.class = cls;
          await this.configService.deleteClassValue(classValue);
          if (cls.parent)
          {
            const parentClsValue: ClassValue = _.find(this.filters.get(cls.parent.id).value, value => value.id === classValue.parentId);
            for (const child of parentClsValue.children)
            {
              if (child.id === classValue.id)
              {
                parentClsValue.children.splice(parentClsValue.children.indexOf(child), 1);
              }
            }
          }
          cls.classValues = _.without(cls.classValues, classValue);
          cls.reducedClassValues = _.without(cls.reducedClassValues, classValue);
          cls.filteredValues = _.without(cls.filteredValues, classValue);
          this.filters.controls[cls.id].setValue([]);
        }
      }
      catch (err)
      {
        await this.feedbackService.notifyError(`Error deleting '${cls.name}': ${err.error}`, err);
      }
    }
  }

  documentTypesChanged = async (value: any, emit: boolean = true) => // value can be an object or an array, depending on this.multiSelection
  {
    const lastFilter = _.clone(this.filters);
    this.selectedClasses = [];
    this.filters = this.fb.group({});

    if (!value)
    {
      return;
    }

    if (!this.multiDocTypeSelection)
    {
      this.selectedDocTypes = [value];
    }
    else
    {
      this.selectedDocTypes = value;
    }
    // if several docTypes have the same (root) class, we just use it once
    let uniqueRootClasses: Class[] = [];

    // if none doctypes are selected is the same as all
    const realDocTypeSelection = this.selectedDocTypes?.length === 0 ? this.documentTypes : this.selectedDocTypes;
    for (const docType of realDocTypeSelection)
    {
      if (docType.classes)
      {
        for (const docTypeClass of docType.classes)
        {
          const originalClass = this.classes.find(c => c.id === docTypeClass.id);
          if (!_.some(uniqueRootClasses, cls => cls.id === originalClass.id))
          {
            uniqueRootClasses.push(originalClass);
          }
        }
      }
    }
    uniqueRootClasses = _.sortBy(uniqueRootClasses, c => c.order);
    // let's reduce the root classes with their child trees to a plain list
    this.selectedClasses = this.docService.getPlainClassList ? this.docService.getPlainClassList(uniqueRootClasses) : [];

    this.selectedClasses.forEach(c => c.classValues = _.sortBy(c.classValues, cv => cv.name.toLowerCase()));
    for (const cls of this.selectedClasses)
    {
      cls.reducedClassValues = cls.classValues.slice(0);
      cls.filteredValues = cls.classValues.slice(0);
    }

    // for each class in the plain list, let's add a control to the UI and subscribe to its changes
    for (const cls of this.selectedClasses)
    {
      this.filters.addControl(cls.id, new UntypedFormControl());
      this.filters.controls[cls.id].valueChanges.subscribe(this.classValueChanged);
    }
    this.filters.markAllAsTouched();
    // let's set their previous values
    if (lastFilter)
    {
      Object.keys(this.filters.controls).forEach(clsCtrlId =>
      {
        if (lastFilter.controls[clsCtrlId])
        {
          this.filters.controls[clsCtrlId].setValue(lastFilter.controls[clsCtrlId].value, this.noEventOptions);
        }
      });
    }

    // let's preselect single values "top down" recursively, starting from the root classes
    if (this.autoSelect)
    {
      for (const cls of uniqueRootClasses)
      {
        if (cls.classValues && cls.classValues.length === 1)
        {
          this.filters.controls[cls.id].setValue(cls.classValues, this.noEventOptions);
          this.classValueChanged(cls.classValues, false);
        }
      }
    }
    if (emit)
    {
      this.emit();
    }
  };

  classValueChanged = async (data: any, emit: boolean = true) => // data can be an object or an array, depending on this.multiSelection
  {
    if (data)
    {
      if (data.length === undefined)
      {
        data = [data];
      }
      if (data.length > 0 && data[0].classId)
      {
        // a classValue was selected
        // so we have to set all parents' values recursively
        // and reduce the child values
        // and autoselect single child values
        const classValues = <ClassValue[]>data;
        this.setParentValues(classValues);
        this.reduceChildValues(classValues);
        if (this.autoSelect)
        {
          this.autoSelectChildrensSingleValues(classValues[0].classId);
        }
      }
      else if (data[0] !== 'blank')
      {
        // null was selected for this class
        // so we have to reset all the child's reduced values to the complete list of values!
        for (const cls of this.selectedClasses)
        {
          const clsCtrl = this.filters.get(cls.id).value;
          if (!clsCtrl || clsCtrl.length === 0)
          {
            this.resetReducedValuesRecursively(cls.children);
          }
        }
      }
    }
    else
    {
      console.log('SHOULD NOT HAPPEN');
    }
    if (emit)
    {
      this.emit();
    }
  };

  /**
   * When the user sets the value of a class that has parent values, we have to go
   * up its tree to set every parent value accordingly
   */
  private setParentValues(clsValues: ClassValue[])
  {
    let parentValue: ClassValue[] = [];
    for (const clsValue of clsValues)
    {
      if (clsValue.parentId)
      {
        const parentClassValue = this.searchClassValue(clsValue.parentId);
        const parentCtrl = this.filters.get(parentClassValue.classId);
        const parentClass = this.selectedClasses.find(cls => cls.id === parentClassValue.classId);
        parentValue.push(parentClass.classValues.find(cv => cv.name === parentClassValue.name));
        if (parentCtrl.value)
        {
          parentValue = _.union(parentValue, parentCtrl.value);
        }
        if (!this.isMultipleSelectionForClass(parentClass))
        {
          parentCtrl.setValue(parentValue[0], this.noEventOptions);
          this.reduceChildValues(parentValue, true);
        }
        else
        {
          parentCtrl.setValue(parentValue, this.noEventOptions);
        }
        this.setParentValues(parentValue);
      }
    }
  }

  private searchClassValue(classValueId: string): ClassValue
  {
    for (let i = 0; i < this.selectedClasses.length; i++)
    {
      const cls = this.selectedClasses[i];
      if (!cls.classValues)
      {
        continue;
      }
      for (let j = 0; j < cls.classValues.length; j++)
      {
        if (cls.classValues[j].id === classValueId)
        {
          return cls.classValues[j];
        }
      }
    }
    throw Error('not found');
  }

  /**
   * When a classValue is selected, the child classes should just show the values in the dropdown
   * which belong to this classValue. It also resets the value of each child control to undefined (unless keepValues = true).
   */
  private reduceChildValues(clsValues: ClassValue[], keepValues?: boolean)
  {
    const cls = this.selectedClasses.find(c => c.id === clsValues[0]?.classId);
    if (cls !== undefined && cls.children !== undefined && cls.children.length > 0)
    {
      for (const childClass of cls.children)
      {
        childClass.reducedClassValues = [];
        childClass.blankCheck = false;
        for (const clsValue of clsValues)
        {
          if (!keepValues)
          {
            this.filters.get(childClass.id).setValue(undefined, this.noEventOptions);
          }
          const reducedChildValues = childClass.classValues.filter(cv => cv.parentId === clsValue.id);
          childClass.reducedClassValues = childClass.reducedClassValues.concat(reducedChildValues);
          // childClass.filteredValues = reducedChildValues.slice(0);
          this.resetClassFilteredValues(childClass);
        }
        this.resetReducedValuesRecursively(childClass.children);
      }
    }
  }

  /**
   * To reset the reducedValues of each given class and its subclasses
   * to the original (full) list of classValues
   */
  private resetReducedValuesRecursively(classes: Class[])
  {
    if (classes && classes.length > 0)
    {
      for (const child of classes)
      {
        child.blankCheck = false;
        this.filters.get(child.id).setValue(undefined, this.noEventOptions);
        child.reducedClassValues = child.classValues.slice(0);
        child.filteredValues = child.classValues.slice(0);
        this.resetReducedValuesRecursively(child.children);
      }
    }
  }

  private autoSelectChildrensSingleValues(classId: string)
  {
    const cls = _.find(this.selectedClasses, c => c.id === classId);
    if (cls.children && cls.children.length > 0)
    {
      for (let child of cls.children)
      {
        child = _.find(this.selectedClasses, c => c.id === child.id);
        if (child.reducedClassValues.length === 1)
        {
          this.filters.get(child.id).setValue(child.reducedClassValues, this.noEventOptions);
          if (this.multiClassSelection)
          {
            this.classValueChanged(child.reducedClassValues, false);
          }
          else
          {
            this.classValueChanged(child.reducedClassValues[0], false);
          }
        }
      }
    }
  }

  getClassPlaceholder(cls: Class): string
  {
    const classControl = this.filters.get(cls.id);
    if (this.showAllText === true && (classControl.value === undefined || classControl.value === ''))
    {
      return `All ${cls.name}`;
    }
    return cls.name;
  }

  private valueDialog(
    title: string,
    existingClassValues: ClassValue[],
    parentClassValue?: ClassValue,
    currentClassValue?: ClassValue,
    name?: string): Promise<string[]>
  {
    return new Promise<string[]>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(AddClassValueDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          title: title,
          existingClassValues: existingClassValues,
          parentClassValue: parentClassValue,
          currentClassValue: currentClassValue,
          name: name
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

  private askDialog(question: string): Promise<string>
  {
    return new Promise<string>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }

  async emit(event?: any)
  {
    // The UI needs time to refresh the FormControllers
    await this.delay(200);

    let docTypeIds: string[] = [];
    docTypeIds = _.pluck(this.selectedDocTypes, 'id');
    const classSelections: ClassSelection[] = [];
    let classValueIds: string[];
    for (const cls of this.selectedClasses)
    {
      classValueIds = [];
      if (cls.classValues)
      {
        let currentClsValues = this.filters.get(cls.id).value;
        if (currentClsValues && currentClsValues.length === undefined)
        {
          // In case of !multiselection or cls.singleValue
          currentClsValues = [currentClsValues];
        }
        for (const classValue of cls.classValues)
        {
          if (_.some(currentClsValues, clsValue => clsValue === classValue))
          {
            classValueIds.push(classValue.id);
          }
        }
        classSelections.push({classId: cls.id, classValueIds: classValueIds, blank: cls.blankCheck});
      }
      else
      {
        classSelections.push({classId: cls.id, classValueIds: [], blank: cls.blankCheck});
      }
    }

    const id = this.authService.getCurrentIdentity();
    const newDocumentFilter: DocumentFilter = {
      searchText: this.search.value,
      companyId: id && id.company ? id.company.id : undefined,
      docTypeIds: docTypeIds,
      classSelections: classSelections,
      page: undefined,
      skipPreviewUrls: undefined,
      areFiltersValid: this.filters.valid,
      missingValues:
        _.some(classSelections, clsSelection => clsSelection && clsSelection.classValueIds && clsSelection.classValueIds.length === 0)
    };

    // if we need to add these filters, and one of the selections is false, we add them
    // (if there are all true, no filter is like 'all')
    if (this.showStatusFilter && (!this.approved || !this.draft || !this.preapproved))
    {
      newDocumentFilter.versionStates = [];
      if (this.approved)
      {
        newDocumentFilter.versionStates.push('Approved');
      }
      if (this.draft)
      {
        newDocumentFilter.versionStates.push('Draft');
      }
      if (this.preapproved)
      {
        newDocumentFilter.versionStates.push('Preapproved');
      }
    }
    if (newDocumentFilter &&
        newDocumentFilter.docTypeIds &&
        newDocumentFilter.docTypeIds.length === 1 &&
        newDocumentFilter.docTypeIds[0] === undefined)
    {
      newDocumentFilter.docTypeIds = [];
    }

    // if (newDocumentFilter.docTypeIds.length === this.documentTypes.length)
    // {
    //   newDocumentFilter.docTypeIds = [];
    // }
    newDocumentFilter.classSelections =
      _.filter(newDocumentFilter.classSelections, cs => (cs.classValueIds && cs.classValueIds.length > 0) || cs.blank);

    this.filterChanged.emit(newDocumentFilter);
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  classifyWithContent()
  {
    this.doContentClassification.emit(this.selectedClasses);
  }
}
